import "./index.css";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";

// render application
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

try {
  if (window.TelegramGameProxy && typeof window.TelegramGameProxy.receiveEvent === 'function') {
      window.TelegramGameProxy.receiveEvent();
  }
} catch (error) {
  console.error("Ошибка проигнорирована: ", error);
}
